<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_tab">
          <div class="btn_wrap">
            <button class="" @click="fnPage">아이디 찾기</button
            ><!--'on'-->
            <button class="on">비밀번호 재설정</button>
          </div>
        </div>
        <router-view />
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'find-password-index',
  setup() {
    const state = componentState()
    return { ...state }
  }
}
</script>

<style scoped></style>
